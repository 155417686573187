import cell_car from './1.jpg';
import hide_eye from './hide-eye.svg'
import show_eye from './show-eye.svg'
import fix_bugs from "./bug-fixing-svgrepo-com.svg";
import listRemove from "./list-remove.svg";
import aggrigate from './baner/aggrigate.svg'
import aggrigateJpg from './baner/aggrigate.jpg'
import aggrigatePng from "./baner/aggrigate-Photoroom.png";
import fone from './baner/fone_1.svg'
import foneGradient from './baner/fone_2.svg'
import fog from './baner/fog.svg'
import test from './baner/test.jpg'
import play from './play_icon.svg';


const help = 'https://botrazbor.ru/media/market/help.svg';
const chat = 'https://botrazbor.ru/media/market/chat.svg';
const user = 'https://botrazbor.ru/media/market/user.svg';
const done = 'https://botrazbor.ru/media/market/done.svg';
const stop = 'https://botrazbor.ru/media/market/stop.svg';
const plus = 'https://botrazbor.ru/media/market/plus.svg';
const cross = 'https://botrazbor.ru/media/market/cross.svg';
const cloud = 'https://botrazbor.ru/media/market/cloud.svg';
const search = 'https://botrazbor.ru/media/market/search.svg';
const engine = 'https://botrazbor.ru/media/market/engine.svg';
const piston = 'https://botrazbor.ru/media/market/piston.svg';
const penRed = 'https://botrazbor.ru/media/market/pen-red.svg';
const checked = 'https://botrazbor.ru/media/market/checked.svg';
const penGrey = 'https://botrazbor.ru/media/market/pen-grey.svg';
const copyDoc = 'https://botrazbor.ru/media/market/copy-doc.svg';
const addFile = 'https://botrazbor.ru/media/market/add-file.svg';
const crossRed = 'https://botrazbor.ru/media/market/cross-red.svg';
const twoCloud = 'https://botrazbor.ru/media/market/two-cloud.svg';
const location = 'https://botrazbor.ru/media/market/location.svg';
const starmenu = 'https://botrazbor.ru/media/market/star-menu.svg';
const userWhite = 'https://botrazbor.ru/media/market/user-white.svg';
const arrowDown = 'https://botrazbor.ru/media/market/arrowDownNoFill.svg';
const myRequest = 'https://botrazbor.ru/media/market/my-request.svg';
const attention = 'https://botrazbor.ru/media/market/attention.svg';
const subscribe = 'https://botrazbor.ru/media/market/subscribe.svg';
const unitTurbo = 'https://botrazbor.ru/media/market/unit-turbo.svg';
const chatCheck = 'https://botrazbor.ru/media/market/chat-check.svg';
const settingRed = 'https://botrazbor.ru/media/market/setting-red.svg';
const bascketRed = 'https://botrazbor.ru/media/market/bascket-red.svg';
const arrowRight = 'https://botrazbor.ru/media/market/arrowRight.svg';
const shieldTick = 'https://botrazbor.ru/media/market/shield-tick.svg';
const heardBlack = 'https://botrazbor.ru/media/market/heard-black.svg';
const docSuccess = 'https://botrazbor.ru/media/market/doc-success.svg';
const editMarket = 'https://botrazbor.ru/media/market/edit-market.svg';
const basketShop = 'https://botrazbor.ru/media/market/basket-shop.png';
const fileSuccess = 'https://botrazbor.ru/media/market/file-success.svg';
const fullRedHart = 'https://botrazbor.ru/media/market/fullRedHart.svg';
const starRaiting = 'https://botrazbor.ru/media/market/StarRaiting.svg';
const background = 'https://botrazbor.ru/media/market/background.png';
const rowVertical = 'https://botrazbor.ru/media/market/row-vertical.svg';
const contentCopy = 'https://botrazbor.ru/media/market/content_copy.svg';
const actionSquer = 'https://botrazbor.ru/media/market/action-squer.svg';
const plusDarkBlue = 'https://botrazbor.ru/media/market/plus-dark-blue.svg';
const settingThree = 'https://botrazbor.ru/media/market/setting-three.svg';
const settingWhite = 'https://botrazbor.ru/media/market/setting-white.svg';
const securityUser = 'https://botrazbor.ru/media/market/security-user.svg';
const shoppingCart = 'https://botrazbor.ru/media/market/shopping-cart.svg';
const bascketWhite = 'https://botrazbor.ru/media/market/bascket-white.svg';
const fakeImageFile = 'https://botrazbor.ru/media/market/fake-image-file.svg';
const arrowRightRed = 'https://botrazbor.ru/media/market/arrow-right-red.svg';
const iconLightTheme = 'https://botrazbor.ru/media/market/iconLightTheme.svg';
const heardRedBorder = 'https://botrazbor.ru/media/market/heard-red-border.svg';
const arrowLeftWhite = 'https://botrazbor.ru/media/market/arrow-left-white.svg';
const fileAdditionOne = 'https://botrazbor.ru/media/market/file-addition-one.svg';
const starStrokeWhite = 'https://botrazbor.ru/media/market/star-stroke-white.svg';
const incomingSetting = 'https://botrazbor.ru/media/market/incoming-setting.svg';
const arrowRightWhite = 'https://botrazbor.ru/media/market/arrow-right-white.svg';
const starFooterActive = 'https://botrazbor.ru/media/market/star-active.svg';
const homeFooterActive = 'https://botrazbor.ru/media/market/home-footer-active.svg';
const starFooterDefault = 'https://botrazbor.ru/media/market/star-footer.svg';
const homeFooterDefault = 'https://botrazbor.ru/media/market/home-footer.svg';
const starRaitingFillRed = 'https://botrazbor.ru/media/market/StarRaitingFillRed.svg';
const marketFooterActive = 'https://botrazbor.ru/media/market/market-footer-active.svg';
const fileAdditionOneRed = 'https://botrazbor.ru/media/market/file-addition-one-red.svg';
const settingRedRotation = "https://botrazbor.ru/media/market/setting-red-rotation.svg";
const marketFooterDefault = 'https://botrazbor.ru/media/market/market-footer.svg';
const profileFooterActive = 'https://botrazbor.ru/media/market/profile-footer-active.svg';
const profileFooterDefault = 'https://botrazbor.ru/media/market/profile-footer.svg';
const createRequestDefault = 'https://botrazbor.ru/media/market/create-request.svg';


export {
  aggrigate,
aggrigateJpg,
aggrigatePng,
fone,
foneGradient,
fog,
test,
    fix_bugs,
play,
    cell_car,
    hide_eye,
    show_eye,
    listRemove,
    help,
    chat,
    user,
    done,
    stop, 
    plus,
    cross,
    cloud,
    search,
    engine,
    piston,
    penRed,
    checked,
    penGrey,
    copyDoc,
    addFile,
    crossRed,
    twoCloud,
    location,
    starmenu,
    userWhite,
    arrowDown,
    myRequest,
    attention,
    subscribe,
    unitTurbo,
    chatCheck,
    settingRed,
    bascketRed,
    arrowRight,
    shieldTick,
    heardBlack,
    docSuccess,
    editMarket,
    basketShop,
    background,
    fileSuccess,
    fullRedHart,
    starRaiting,
    rowVertical,
    contentCopy,
    actionSquer,
    plusDarkBlue,
    settingThree,
    settingWhite,
    securityUser,
    shoppingCart,
    bascketWhite,
    fakeImageFile,
    arrowRightRed,
    iconLightTheme,
    heardRedBorder,
    arrowLeftWhite,
    fileAdditionOne,
    starStrokeWhite,
    incomingSetting,
    arrowRightWhite,
    starFooterActive,
    homeFooterActive,
    starFooterDefault,
    homeFooterDefault,
    starRaitingFillRed,
    marketFooterActive,
    fileAdditionOneRed,
    settingRedRotation,
    marketFooterDefault,
    profileFooterActive,
    profileFooterDefault,
    createRequestDefault,
}
